/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "c93487e0f3f94a38a321436c5693f888",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "c93487e0f3f94a38a321436c5693f888",
            "region": "us-west-2"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "authenticate",
            "endpoint": "https://dswd39uthj.execute-api.us-west-2.amazonaws.com/lifextest",
            "region": "us-west-2"
        },
        {
            "name": "genAIAdmin",
            "endpoint": "https://f9mksgsc7g.execute-api.us-west-2.amazonaws.com/lifextest",
            "region": "us-west-2"
        },
        {
            "name": "genAIFile",
            "endpoint": "https://srrr4tptxd.execute-api.us-west-2.amazonaws.com/lifextest",
            "region": "us-west-2"
        },
        {
            "name": "genAISetup",
            "endpoint": "https://tx8l04av2h.execute-api.us-west-2.amazonaws.com/lifextest",
            "region": "us-west-2"
        },
        {
            "name": "genAIUser",
            "endpoint": "https://6cgqof4hg5.execute-api.us-west-2.amazonaws.com/lifextest",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:e8558323-bb95-4f3c-91c1-9e0b822c7bfe",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_CPCNM8NAq",
    "aws_user_pools_web_client_id": "177mm1seu94mb2fcla1j7sj3qf",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "genai-content115003-lifextest",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "genAIViews-lifextest",
            "region": "us-west-2"
        },
        {
            "tableName": "genAiReq-lifextest",
            "region": "us-west-2"
        },
        {
            "tableName": "genAiTenant-lifextest",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
